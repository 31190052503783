body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: black;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@font-face {
    font-family: 'InterBlack';
    src: local('InterBlack'), url(./Assets/Fonts/Inter/Inter-Black-slnt=0.ttf) format('truetype');
}
@font-face {
    font-family: 'InterLight';
    src: local('InterLight'), url(./Assets/Fonts/Inter/Inter-Light-slnt=0.ttf) format('truetype');
}
@font-face {
    font-family: 'InterBold';
    src: local('InterBold'), url(./Assets/Fonts/Inter/Inter-Bold-slnt=0.ttf) format('truetype');
}
@font-face {
    font-family: 'InterMedium';
    src: local('InterMedium'), url(./Assets/Fonts/Inter/Inter-Medium-slnt=0.ttf) format('truetype');
}
@font-face {
    font-family: 'InterRegular';
    src: local('InterRegular'), url(./Assets/Fonts/Inter/Inter-Regular-slnt=0.ttf) format('truetype');
}
@font-face {
    font-family: 'IBMPlexSansRegular';
    src: local('IBMPlexSansRegular'), url(./Assets/Fonts/IBMPlexSans/IBMPlexSans-Regular.ttf) format('truetype');
}
@font-face {
    font-family: 'IBMPlexSansBold';
    src: local('IBMPlexSansBold'), url(./Assets/Fonts/IBMPlexSans/IBMPlexSans-Bold.ttf) format('truetype');
}
